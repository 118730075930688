<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  isCoompetitionOrRace: boolean;
  position?: number;
  isChangePositionColor?: boolean;
  isTie?: boolean;
}

const props = defineProps<Props>();
const { t } = useI18n();

const classPosition = computed<string>(() => {
  if (props.isTie) {
    return '';
  }

  return props.isCoompetitionOrRace && props.position && props.position <= 3
    ? 'app-position--podium'
    : '';
});

const userPosition = computed<string>(() => {
  if (!props.position) {
    return '-';
  }
  return props.isTie ? t('leaderboard.tie') : `${props.position}`;
});

const classTopThreePositions = computed<string>(() => {
  if (
    !props.position ||
    props.position > 3 ||
    !props.isCoompetitionOrRace ||
    props.isTie
  ) {
    return '';
  }
  return classPosition.value === 'app-position--podium' && props.position === 1
    ? 'app-position--first'
    : 'app-position--second-third';
});
</script>

<template>
  <div :class="['app-position', classPosition, classTopThreePositions]">
    {{ userPosition }}
  </div>
</template>

<style scoped lang="scss">
.app-position {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: $white;
  padding: 0px 4px;
  border-radius: 4px;
  box-shadow: $primary-shadow;
  height: 26px;
  font-weight: 700;
  color: $gray-800;
}

.app-position--podium {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.app-position--first {
  background: $primary-color;
  font-weight: bold;
  border: 1px solid $primary-color;
  color: $white;
}

.app-position--second-third {
  font-weight: bold;
  color: $primary-color;
  border: 1px solid $primary-color;
  background-color: $primary-background;
}
</style>
