<template>
  <q-table
    v-bind="$props"
    ref="table"
    v-model:pagination="paginationModel"
    class="app-table__content"
    data-testid="app-table-content"
    :class="{
      'app-table__content--no-data': !rows.length || counter == 0,
      'app-table__content--checkbox': selection === 'multiple',
    }"
    :filter="filterTable"
    :hide-header="hideHeaders"
    :wrap-cells="wrapCells"
    :loading="loading"
    :rows-per-page-options="rowsPerPageOptions"
    :hide-pagination="!showPagination"
    flat
    @request="onRequest"
  >
    <template v-for="(_, slot, index) of $slots" :key="index" #[slot]="scope">
      <q-td
        v-if="isBodyCellElement(slot)"
        :props="scope"
        :class="[
          { 'selected-row': selectedRowIndex === scope.rowIndex || false },
        ]"
        @click="setSelectedRow(scope.rowIndex)"
      >
        <slot :name="slot" v-bind="scope" />
      </q-td>
      <slot v-else :name="slot" v-bind="scope" />
    </template>
    <template #top-left>
      <div
        v-if="$slots.Filters || $slots.Title"
        data-testid="app-table-filters-title"
      >
        <slot name="Filters" />
        <slot name="Title" />
      </div>
      <slot name="header-left" />
      <AppSearch v-if="search" v-model="filter" data-testid="table-search" />
    </template>
    <template v-if="showCounter && counter > 4" #top-right>
      <AppButton
        class="store-table__see-all-tag"
        type="tertiary"
        size="S"
        data-testid="store-table-see-all-tag"
        @click="$emit('counterClick')"
      >
        {{ $t('store.seeAll', [counter]) }}
        <AppIcon :name="roundArrowForward" size="20px" />
      </AppButton>
    </template>
    <template #body-selection="props">
      <q-td
        :class="[
          { 'selected-row': selectedRowIndex === props.rowIndex || false },
        ]"
        @click="setSelectedRow(props.rowIndex)"
      >
        <AppCheckbox v-model="props.selected" />
      </q-td>
    </template>
    <template #body-cell="props">
      <q-td
        :props="props"
        :class="[
          { 'selected-row': selectedRowIndex === props.rowIndex || false },
        ]"
        @click="setSelectedRow(props.rowIndex)"
      >
        <div class="text--ellipsis">
          {{ props.value }}
        </div>
      </q-td>
    </template>
    <template #no-data>
      <AppNoData
        v-if="!loading"
        class="app-table__no-data"
        :message="noDataMessageKey"
      />
    </template>
    <template v-if="!dataLoaded && !rows.length" #loading>
      <AppLoadingRow
        v-for="element in numberOfLoadingRows"
        :key="element"
        :number-of-skeletons="skeletons.length"
        :skeletons="skeletons"
      />
    </template>
  </q-table>
</template>

<script lang="ts">
import { QTable } from 'quasar';
import { defineComponent, PropType } from 'vue';
import { roundArrowForward } from '@quasar/extras/material-icons-round';
import {
  AppTableFilterType,
  Pagination,
  RequestProp,
} from '@/shared/types/components';
import { mapStores } from 'pinia';
import useSharedStore from '@/store/shared/useSharedStore';
import notify from '@/shared/helpers/notify/notify';
import { AppButton } from '@/plugins/commons';
import AppIcon from '../AppIcon/AppIcon.vue';
import AppSearch from '../AppSearch/AppSearch.vue';
import AppNoData from '../AppNoData/AppNoData.vue';
import AppCheckbox from '../AppCheckbox/AppCheckbox.vue';
import AppLoadingRow from '../AppLoadingRow/AppLoadingRow.vue';
import skeletons from '../AppTableLoader';

export default defineComponent({
  name: 'AppTable',

  components: {
    AppSearch,
    AppButton,
    AppIcon,
    AppNoData,
    AppCheckbox,
    AppLoadingRow,
  },

  props: {
    pagination: {
      type: QTable.pagination,
      default: () => undefined,
    },
    rowsPerPageOptions: {
      type: Array,
      default: () => [5, 10, 15, 20, 50, 0],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array as PropType<unknown[]>,
      default: () => [],
    },
    visibleColumns: {
      type: Array,
      default: undefined,
    },
    rowIndex: {
      type: String,
      default: undefined,
    },
    externalFilter: {
      type: String,
      default: undefined,
    },
    noDataMessageKey: {
      type: String,
      default: '',
    },
    virtualScroll: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: false,
    },
    wrapCells: {
      type: Boolean,
      default: true,
    },
    selection: {
      type: String,
      default: 'none',
    },
    shouldDismissRowHighlight: {
      type: Boolean,
      default: false,
    },
    search: Boolean,
    showCounter: Boolean,
    request: {
      type: Function,
      default: undefined,
    },
    numberOfLoadingRows: {
      type: Number,
      default: 5,
    },
    rowsNumber: {
      type: Number,
      default: 1,
    },
    filterType: {
      type: String as PropType<AppTableFilterType>,
      default: 'remote',
    },
  },

  emits: ['counterClick', 'filteredSortedRows', 'update:pagination'],

  data() {
    return {
      skeletons,
      filter: '',
      counter: 0,
      tableHeight: 0,
      isInputFocused: false,
      filteredSortedRows: [] as typeof QTable.filteredSortedRows,
      roundArrowForward,
      loading: false,
      dataLoaded: false,
      paginationParameters: {
        sortBy: '',
        descending: false,
        page: 1,
        rowsPerPage: 10,
        rowsNumber: 1,
      } as Pagination,
      selectedRowIndex: undefined as number | undefined,
    };
  },

  computed: {
    ...mapStores(useSharedStore),

    hideHeaders(): boolean {
      return (
        ((!this.rows.length || this.counter === 0) && !this.loading) ||
        ((!this.rows.length || this.counter === 0) && !this.dataLoaded)
      );
    },

    filterTable(): string {
      const filter = this.filter || this.externalFilter;
      return filter && filter.length >= 3 ? filter : '';
    },

    paginationModel: {
      get(): Pagination | undefined {
        if (this.filterType === 'local') {
          return undefined;
        }

        return this.paginationParameters;
      },

      set(newValue: Pagination) {
        this.paginationParameters = newValue;
      },
    },
  },

  watch: {
    filteredSortedRows() {
      this.$emit('filteredSortedRows', this.filteredSortedRows);
    },

    rowsNumber: {
      handler(newValue: number) {
        this.paginationParameters.rowsNumber = newValue;
      },
      immediate: true,
    },

    shouldDismissRowHighlight(newValue: boolean) {
      if (!newValue) {
        this.selectedRowIndex = undefined;
      }
    },
  },

  mounted() {
    window.addEventListener('mousedown', this.handleMouseDown);
    window.addEventListener('resize', this.onWindowResize);

    if (this.pagination) this.paginationParameters = this.pagination;
    const table = this.$refs.table as typeof QTable;
    this.counter = this.rows.length;

    this.$watch(
      () => table.computedRowsNumber,
      (newComputedRowsNumber: number) => {
        this.counter = newComputedRowsNumber;
      },
    );

    this.$watch(
      () => table.filteredSortedRows,
      (newFilteredSortedRows: typeof QTable.filteredSortedRows) => {
        this.filteredSortedRows = newFilteredSortedRows;
        this.counter = newFilteredSortedRows.length;
      },
    );

    if (this.request) this.requestServerInteraction();

    this.$nextTick(() => {
      this.onWindowResize();
    });
  },

  unmounted() {
    window.removeEventListener('resize', this.onWindowResize);
    window.removeEventListener('mousedown', this.handleMouseDown);
  },

  methods: {
    onWindowResize() {
      const tableContentHeight: number =
        document.querySelector('.app-table__content')?.getBoundingClientRect()
          .top || 0;

      this.tableHeight =
        document.documentElement.clientHeight - tableContentHeight - 4;
    },

    setSelectedRow(index: number): void {
      this.selectedRowIndex = index;
    },

    isBodyCellElement(slot: string | number): boolean {
      return String(slot).includes('body-cell');
    },

    requestServerInteraction(): void {
      (this.$refs.table as QTable).requestServerInteraction();
    },

    resetPagination(): void {
      this.paginationParameters.page = 1;
      (this.$refs.table as QTable).requestServerInteraction();
    },

    async onRequest(request: RequestProp): Promise<void> {
      this.loading = true;
      try {
        this.paginationParameters = request.pagination as Pagination;

        await this.request?.(request.pagination, request.filter);

        this.dataLoaded = true;
      } catch (error) {
        this.notify(this.$t('errors.general.wrong'), 'danger');
      } finally {
        this.loading = false;
      }
    },

    handleMouseDown(event: MouseEvent): void {
      const target = event.target as HTMLElement;

      const appTableElement = this.$el as HTMLElement;

      const appTableBodyElement = appTableElement.querySelector(
        '.q-virtual-scroll__content',
      ) as HTMLElement;

      const documentPositionComparison =
        target.compareDocumentPosition(appTableBodyElement);

      if (
        documentPositionComparison !== Node.DOCUMENT_POSITION_PRECEDING &&
        documentPositionComparison !== Node.DOCUMENT_TYPE_NODE
      ) {
        this.selectedRowIndex = undefined;
      }
    },

    notify,
  },
});
</script>

<style scoped lang="scss">
.app-table__content--no-data {
  background: transparent;
}

.app-table__content {
  background: transparent;
  overflow: hidden;
  flex-grow: 1;
}

.app-table__content--no-data :deep(.q-table__bottom) {
  border: none;
  padding: 0;
}

.app-table__loading-container {
  flex-grow: 1;
}

.app-table__content :deep(table) {
  overflow-y: hidden;
}

.app-table__content :deep(.table-columns) {
  background-color: $gray-25;
  color: $gray-600;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  font-style: normal;
  vertical-align: top;
}

.app-table__content :deep(tbody tr td),
.table-text {
  color: $gray-800;
  font-size: 14px;
  word-break: break-word;
  z-index: 0;
}
.app-table__content :deep(table thead tr th):hover {
  background-color: $gray-50;
  border-bottom-width: 0;
}

.app-table__content :deep(table thead tr th) {
  border-bottom-width: 0;
}

.app-table__content :deep(table thead tr:first-child) {
  height: 35px;
  background-color: $white;
}

.app-table__content :deep(table thead tr:first-child th),
.app-table__content :deep(table tbody tr td) {
  padding: 8px 16px;
}

.app-table__content :deep(table thead tr th) {
  background-color: $gray-25;
}

.app-table__content :deep(table tbody tr) {
  height: 51px;
}

.app-table__content
  .app-table__content__search__input
  :deep(input::placeholder) {
  color: $primary-color;
}
.app-table__content :deep(.q-table__top) {
  padding: 0 0 16px 0;
  background-color: $gray-25;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.store-table__see-all-tag {
  font-weight: 600;
}

.store-table__see-all-tag :deep(.q-btn__content) {
  gap: 7px;
}

.app-table__content :deep(.app-table__content__no-data__label) {
  color: $gray-800;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.app-table__content :deep(.q-table__middle) {
  border-radius: 16px;
  background-color: $white;
  flex-grow: 0;
}

.app-table__content :deep(.q-checkbox__bg) {
  color: $gray-500;
  border-radius: 3px;
}
.app-table__content :deep(.q-checkbox__inner--truthy .q-checkbox__bg),
.app-table__content :deep(.q-checkbox__inner--indet .q-checkbox__bg) {
  background-color: $primary-color;
  border: 2px solid $primary-color;
}

.app-table__no-data {
  width: 100%;
}

.app-table__content :deep(.q-table__top .q-table__control) {
  gap: 16px;
}

.app-table__content :deep(.q-table__top .q-table__control:last-child) {
  flex-shrink: 0;
}

.app-table__content :deep(.q-checkbox__inner) {
  font-size: 32px;
}

.app-table__content--checkbox
  :deep(table thead tr:first-child th.q-table--col-auto-width) {
  padding: 0 16px;
}

.app-table__content.app-table__content--checkbox .app-checkbox,
.app-table__content--checkbox :deep(table tbody tr td.q-table--col-auto-width) {
  padding: 0;
}

.app-table__content--checkbox
  :deep(table tbody tr td.q-table--col-auto-width > td:after) {
  display: none;
}

.app-table__content--checkbox
  :deep(table tbody tr:hover .q-table--col-auto-width td.selected-row:after) {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.03);
  display: block;
}

.app-table__content :deep(thead tr),
.app-table__content :deep(tbody td) {
  height: 59px;
}

.app-table__content :deep(thead tr) {
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 2;
}

.app-table__content :deep(td:first-child),
.app-table__content :deep(th:first-child) {
  position: sticky;
  left: 0;
}

.app-table__content.app-table__content--checkbox :deep(td:nth-child(2)),
.app-table__content.app-table__content--checkbox :deep(th:nth-child(2)) {
  position: sticky;
  left: 64px;
}

.app-table__content :deep(tr > td:first-child),
.app-table__content--checkbox :deep(td:nth-child(2)) {
  z-index: 1;
  background-color: $white;
}

.app-table__content
  :deep(.q-table__middle .q-table .q-virtual-scroll__content .selected-row) {
  background-color: $gray-50;
}

.app-table__content :deep(tbody) {
  scroll-margin-top: 59px;
}

.app-table__content--checkbox :deep(tr:hover .selected-row),
:deep(.q-table tbody td:before),
:deep(.q-table tbody td:after) {
  z-index: 1;
}

.app-table__content :deep(.q-td.text-right > *) {
  justify-content: flex-end;
}
</style>
